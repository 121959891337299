import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  Breadcrumbs,
  TextBox,
  BannerLinks,
  BannerSmall,
  ProjectPagination,
  HeaderSlider,
  SplitView
} from '../../components'

import HoverButton from '../../components/HoverButton'
import boxes from '../../components/BannerLinks/DefaultBox'
import { generateBreadcrumbsLinks } from '../../utils/index'

import alpinamed from '../../assets/pagination.alpinamed.png'
import alupress from '../../assets/banner.alupress.png'
import image5 from '../../assets/bannerlinks/bannerbox.ikb.jpg'

import home from '../../assets/projekte/alupress/home.png'
import fullservice from '../../assets/projekte/alupress/fullservice.png'

const slides = [
  { eachSlide: home, alt: 'Alupress Website' },
  { eachSlide: fullservice, alt: 'Alupress Website' }
]

const Alupress = () => {
  const links = generateBreadcrumbsLinks('/projekte/alupress', 'Alupress')
  return (
    <Layout margin={false} color="white">
      <Helmet
        title="Alupress"
        meta={[
          {
            name: 'description',
            content: 'Alupress und ihre Homepage bei holzweg!'
          },
          {
            name: 'keywords',
            content:
              'Alupress holzweg, Projekt holzweg, Innsbruck, Webseite holzweg, Alupress'
          },
          {
            property: 'og:description',
            content: 'Alupress und ihre Homepage bei holzweg!'
          },
          {
            property: 'og:image',
            content:
              'https://www.holzweg.com/static/banner.alupress-003759f25e6b8ff1fc0e3ca5b64b4b2f.png'
          }
        ]}
      />
      <BannerSmall title="Alupress" image={alupress} parallaxOFF={true} />
      <Breadcrumbs
        links={links}
        backLinkAlias="Übersicht aller aktuellen Projekte"
      />
      <TextBox
        title={
          <>
            <div>Alupress Website Relaunch</div>
            <HoverButton
              buttonStyle=""
              dataText="alupress.com"
              dataTitle="Dive In!"
              link={'https://www.alupress.com'}
            />
          </>
        }
        text={
          <span>
            <p>
              Das Webentwicklungsprojekt für die Alupress AG ist erfolgreich
              abgeschlossen! Durch den geschickten Einsatz von Typo3 haben wir
              eine Webseite geschaffen, die nicht nur den hohen Erwartungen
              unseres Kunden entspricht, sondern diese sogar übertrifft. Die
              bewusste Wahl von Typo3 für unser Projekt erwies sich als kluge
              Entscheidung, da es uns ermöglichte, eine anpassbare Website zu
              entwickeln, die sowohl eine reibungslose Inhaltsintegration als
              auch eine benutzerfreundliche Erfahrung gewährleistet. Modernes
              Design und intuitive Navigation erwarten die Besucher. Unser
              herzlicher Dank gilt der Alupress AG für das Vertrauen in unsere
              Fähigkeiten. Wir freuen uns auf kommende spannende Projekte, die
              uns gemeinsam herausfordern und inspirieren werden.
            </p>
          </span>
        }
      />
      <SplitView
        displayImageLeft={false}
        title="Interaktive Website - Moderne UI-Elemente"
        text={
          <>
            <br />
            <p>
              Hier haben wir gezielt fortschrittliche UI-Elemente implementiert,
              um eine interaktive Nutzererfahrung auf unserer Website zu
              schaffen. In enger Kooperation mit holzweg haben wir innovative
              Technologien eingesetzt, um unseren Besuchern eine dynamische
              Erkundung unserer Produkte zu ermöglichen. Von animierten
              Bildergalerien bis hin zu intuitiven Produktvergleichen – diese
              UI-Elemente machen das Stöbern auf alupress.com zu einem
              fesselnden Erlebnis. Entdecken Sie selbst, wie wir Modernität und
              Interaktivität vereinen, indem Sie unsere Webseite besuchen.
            </p>
            <br />
            {/* <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.
            </p> */}
          </>
        }
        linkText="Mehr Eindrücke entdecken"
        link="#slider"
        backgroundImage={fullservice}
      />
      <HeaderSlider slides={slides} />
      <TextBox title="Weitere Projekte" titleMods={['center']} />
      <ProjectPagination
        titleLeft="Alpinamed Übernahme"
        imageLeft={alpinamed}
        leftLink="/projekte/alpinamed"
        titleRight="IKB Multiportal"
        imageRight={image5}
        rightLink="/projekte/ikb"
      />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default Alupress
